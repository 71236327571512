<script>
import appConfig from "@/app.config";
import {mapGetters} from 'vuex'
import moment from 'moment'


export default {
  page: {
    title: "Payment Success",
    meta: [{ name: "description", content: appConfig.description }]
  },

  async created() {
    if(!this.billingExists.hasOwnProperty("updated_at")){
      this.$router.push({name : 'payment', query : {appRoute : this.$route.query.appRoute}});
    }
    
    // window.console.log(this.billingExists);
  },
  methods : {
    rupiah : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number).replace(",00", '')

  },
  computed : {
    ...mapGetters({
        billingExists : 'getBillingExists',
        monthName : 'getMonthName'
    }),
    
    listNamaTagihan() {
      if(!this.billingExists.hasOwnProperty('detil_tagihan')){
        return '';
      }
      return this.billingExists.detil_tagihan.map((t) => t.wajib_nama).join(', ');
    },
    tanggalTransaksi() {
      // window.console.log(new Date(this.billingExists.updated_at));
      const date_transaction = moment(this.billingExists.updated_at)
      return `${date_transaction.date().toString().padStart(2, '0')} ${this.monthName[date_transaction.month()]} ${date_transaction.year()}`
    }
  }
}
</script>
<template>
  <div class="account-pages my-3 pt-3" style="overflow: hidden;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="display-2 fw-medium">
              <!-- 4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4 -->
            <img src="@/assets/images/payment-success.png" alt class="img-fluid" style="max-width: 60%;"/>
            </h4>
            <h4 class="text-uppercase">Terima Kasih </h4>
            <div class="mt-1 text-center">
                <p>
                    Anda sudah melakukan transaksi sebesar <strong>{{rupiah(billingExists.gross_amount)}}</strong> untuk pembayaran <strong>{{listNamaTagihan}}</strong>. <br/>
                    Tanggal transaksi : {{tanggalTransaksi}}
                </p>
                <p>Simpan selalu bukti transaksi pembayaran anda,<br/>karena satu-satunya bukti pembayaran yang sah adalah bukti transaksi berhasil dari masing-masing channel pembayaran.<br/><br/>Kami merekomendasikan anda untuk selalu menyimpan setiap bukti transaksi pembayaran berhasil dari channel apapun karena itu akan membantu anda saat terjadi masalah pembayaran tidak masuk ke sistem keuangan sekolah. Terima kasih.</p>
              <!-- <router-link tag="a" class="btn btn-primary" style="width: 100%" to="/">Back to Payment</router-link> -->
            </div>
            <div class="mt-1 text-center">
                <router-link class="btn btn-primary" :to="{name : 'payment', query : {appRoute : this.$route.query.appRoute}}">Kembali ke Daftar Tagihan</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="@/assets/images/error-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style scoped>
p{
    padding: 10px;
}
</style>

